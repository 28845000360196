<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CButton @click="collapse=!collapse" color="primary" class="mb-2 mr-2">
                    Filter
                </CButton>
                <CCollapse :show="collapse" :duration="400">
                    <CCard>
                        <CCardHeader>
                            <strong>Filter Options</strong>
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.id.val" label="ID" />
                                </CCol>
                                <CCol sm="3">
                                    <label>Data Source</label>
                                    <model-select :options="data_sources" v-model="form.data_source_id.val" placeholder="Please Select">
                                    </model-select>
                                    <small class="form-text text-muted w-100">{{form.data_source_id.inv}}</small>
                                </CCol>
                                <CCol sm="3">
                                    <label>Created at</label>
                                    <date-picker placeholder="Please Select" locale="en" v-model="form.created_at.val"></date-picker>
                                </CCol>
                            </CRow>
                            <br>
                            <div class="form-group form-actions">
                                <CButton @click="filter=true;page=1;count();get();" color="primary" class="mr-2">Filter</CButton>
                                <CButton @click="remove_filter" color="danger">Remove Filter</CButton>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCollapse>
            </CCol>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Data Sources Logs</strong>
                        <div class="card-header-actions">
                            Number of All Rows: {{row_count}}
                        </div>
                        </CCardHeader>
                    <CCardBody>
                        
                        <CDataTable hover striped :items="items" :fields="fields" :noItemsView="{ noItems: 'No items to show.' }">
                            <template #data_source_name="data">
                                <td>
                                    <router-link :to="`/data/source/${data.item.data_source_id}/details`">{{data.item.data_source_name}}</router-link>
                                </td>
                            </template>
                            <template #action="data">
                                <td>
                                    <template>
                                    <div class="flex-container">
                                        <div @click="redirect_to(`/data/source/logs/${data.item.id}/details`)"
                                            v-c-tooltip.hover="'Details'">
                                        <CIcon height="20" :content="$options.details"/>
                                        </div>
                                        <div v-show="$store.state.perm['arbitrage.DataSourceLogs'].delete"
                                            @click="delete_modal=true;id=data.item.id"
                                            v-c-tooltip.hover="'Delete'">
                                        <CIcon height="20" :content="$options.delete"/>
                                        </div>
                                    </div>
                                    </template>
                                </td>
                            </template>
                        </CDataTable>
                            <br>
                            <div> 
                                <CPagination
                                :activePage.sync="page"
                                :pages="page_count"
                                align="center"
                                :doubleArrows="true"
                                />
                            </div>
                        </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            title="Alert"
            size="sm"
            color="danger"
            :show.sync="delete_modal"
            >
            <p>Are you sure?</p>
            <template #footer>
                <CButton @click="delete_modal=false" color="secondary">No</CButton>
                <CButton @click="delete_row" color="danger">Yes</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { ModelSelect } from 'vue-search-select'
import { cilListRich, cilTrash } from '@coreui/icons'
export default {
    components: { ModelSelect },
    delete: cilTrash, details: cilListRich,
    data () {
        return {
            row_count: '',
            items: [],
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'data_source_name', label: 'Data Source' },
                { key: 'created_at', label: 'Created at' },
                { key: 'action', label: 'Action' },
            ],
            collapse: false,
            filter: false,
            data_sources: [],
            form: {
                id: {val: '', inv: '', v: { max: 8 }},
                data_source_id: {val: '', inv: '', v: { max: 8 }},
                created_at: {val: '', inv: '', v: { max: 16 }},
            },
            disable: false,
            page: 1,
            page_count: 0,
            offset: 0,
            delete_modal: false,
            id: 0,
        }
    },
    watch: {
        page: {
            handler(){
                this.count();
                this.get();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    },
    mounted(){
        this.count();
        this.get();
        this.get_data_sources();
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        get_data_sources(){
            this.axios.get('arbitrage/data_sources/')
                .then(res => {
                    res.data.map(val => {
                        this.data_sources.push({value: val.id, text: val.name});
                    });
                }) 
        },
        count() {
            if(this.disable == false) {
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('arbitrage/data_source_logs/?count=1' + filter)
                    .then(res => {
                        this.row_count = String(res.data.count).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        if(res.data.count > 100){
                            this.page_count = Math.ceil(res.data.count / 100);
                        } else {
                            this.page_count = 1;
                        }
                    })
            }
        },
        get() {
            if (this.disable == false) {
                this.offset = 0;
                if(this.page != 1){
                    this.offset = (this.page * 100) - 99;
                }
                let filter = '';
                if(this.filter){
                    for (const key in this.form) {
                        if(this.form[key].val != ''){
                            filter += `&${key}=` + this.form[key].val;
                        }
                    }
                }
                this.axios.get('arbitrage/data_source_logs/' 
                    + '?limit=100' 
                    + '&offset=' + this.offset
                    + '&data_sources_info=1'
                    + filter)
                    .then(res => {
                        res.data.map(val => {
                            val.data_source_name = val.data_sources_info.name;
                            val.created_at = this.$moment(val.created_at).format('YYYY-MM-DD HH:mm:ss');
                        });
                        this.items = res.data;
                    })
            }
        },
        delete_row() {
			this.axios.delete('arbitrage/data_source_logs/'
			+ '?id=' + this.id)
			.then(() => {
				this.$notify({
					group: 'foo',
					type: 'success',
					text: 'Deleted Successfully.'
                });
				this.delete_modal = false;
                this.count();
				this.get();
			})
			.catch(() => {
				this.$notify({
					group: 'foo',
					type: 'error',
					text: 'Error! please try again.'
                });
                this.delete_modal = false;
			})
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        remove_filter() {
            for (const key in this.form) {
                this.form[key].val = '';
                this.form[key].inv = '';
            }
            this.disable = false;
            this.filter = false;
            this.page = 1;
            this.count();
            this.get();
        },
    },
}
</script>
